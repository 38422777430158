<template>
    <div v-if="isMounted">
        <Form @submit="handleSubmit" v-slot="{ errors }" class="forms-setting-form" ref="profile-form">
            <div class="content_area">
                <div class="content_wpr">
                    <div class="section_content w-100">
                        <div class="profile_wpr">
                            <div class="profile_left">
                                <div class="user_img">
                                    <img :src="form.profile_pic ? form.profile_pic : require('@/assets/images/male-female.png')" alt="">
                                    <image-upload v-model="form.profile_pic" image-type="coach-logo" :is-avatar="true" :is-button="true" :is-icon="true" icon="fas fa-pencil-alt" upload-text="Avatar" v-if="isEdit" />
                                </div>
                                <button type="button" class="edit_btn" @click="editProfile()" v-if="!isEdit">Edit Info</button>
                                <a :href="`${profileLink}/${selectedClient.stripe_customer_id}`" target="_blank">View Stripe Profile</a>
                            </div>
                            <div class="profile_right">
                                <h3 class="sub_header border-bottom pb-2 mt-5">Personal Information</h3>
                                <div class="setting_wpr col-2">
                                    <div class="form_grp" v-if="!isEdit">
                                        <div class="group_item">
                                            <label class="input_label">Full Name</label>
                                            <h4>{{ form.first_name ? form.first_name : '-' }} {{ form.last_name ? form.last_name : '-' }}</h4>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="isEdit">
                                        <div class="group_item">
                                            <label class="input_label">First Name</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.first_name }">
                                                <Field type="text" name="first_name" v-model="form.first_name" placeholder="ex: Robert Bacins" rules="required" />
                                            </div>
                                            <ErrorMessage name="first_name" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="isEdit">
                                        <div class="group_item">
                                            <label class="input_label">Last Name</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.last_name }">
                                                <Field type="text" name="last_name" v-model="form.last_name" placeholder="ex: Robert Bacins" rules="required" />
                                            </div>
                                            <ErrorMessage name="last_name" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Occupation</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.occupation }">
                                                <Field type="text" name="occupation" v-model="form.occupation" placeholder="Missing Occupation" />
                                            </div>
                                            <h4 v-else>{{ form.occupation ? form.occupation : 'Missing Occupation' }}</h4>
                                            <ErrorMessage name="occupation" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Phone</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.phone }">
                                                <Field type="text" name="phone" v-model="form.phone" placeholder="ex: 18433439321" />
                                            </div>
                                            <h4 v-else>{{ form.phone ? form.phone : '-' }}</h4>
                                            <ErrorMessage name="phone" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Email</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.email }">
                                                <Field type="email" name="email" v-model="form.email" placeholder="ex: hello.robert@thrivecoach.io" rules="required" />
                                            </div>
                                            <h4 v-else>{{ form.email ? form.email : '-' }}</h4>
                                            <ErrorMessage name="email" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <h3 class="sub_header border-bottom pb-2 mt-5">Billing Address</h3>
                                <div class="setting_wpr col-2">
                                    <div class="form_grp w-100">
                                        <div class="group_item">
                                            <label class="input_label">Street</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_address }">
                                                <Field v-model="form.billing_address" name="billing_address">
                                                    <textarea cols="30" rows="10" v-model="form.billing_address" placeholder="ex: 123 Anywhere Street."></textarea>
                                                </Field>
                                            </div>
                                            <h4 v-else>{{ form.billing_address ? form.billing_address : '-' }}</h4>
                                            <ErrorMessage name="billing_address" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Apartment</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_apt }">
                                                <Field type="text" name="billing_apt" v-model="form.billing_apt" placeholder="ex: Skyline" />
                                            </div>
                                            <h4 v-else>{{ form.billing_apt ? form.billing_apt : '-' }}</h4>
                                            <ErrorMessage name="billing_apt" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">City</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_city }">
                                                <Field type="text" name="billing_city" v-model="form.billing_city" placeholder="ex: San Francisco" />
                                            </div>
                                            <h4 v-else>{{ form.billing_city ? form.billing_city : '-' }}</h4>
                                            <ErrorMessage name="billing_city" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">State/Province</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_region }">
                                                <Field type="text" name="billing_region" v-model="form.billing_region" placeholder="ex: California" />
                                            </div>
                                            <h4 v-else>{{ form.billing_region ? form.billing_region : '-' }}</h4>
                                            <ErrorMessage name="billing_region" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Postal Code</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_postal_code }">
                                                <Field type="number" name="billing_postal_code" v-model="form.billing_postal_code" placeholder="ex: 94116" />
                                            </div>
                                            <h4 v-else>{{ form.billing_postal_code ? form.billing_postal_code : '-' }}</h4>
                                            <ErrorMessage name="billing_postal_code" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Country</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_country }">
                                                <Field name="billing_country" v-model="form.billing_country" :class="{ 'has-error': errors.billing_country }">
                                                    <multiselect
                                                    v-model="form.billing_country"
                                                    :options="countries"
                                                    value-prop="code"
                                                    label="country"
                                                    :searchable="true"
                                                    placeholder="Select country"
                                                    autocomplete="nofill"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <h4 v-else>{{ form.billing_country ? getCountryName(form.billing_country) : '-' }}</h4>
                                            <ErrorMessage name="billing_country" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <h3 class="sub_header border-bottom pb-2 mt-5">
                                    Shipping Address
                                    <label for="is-same-address" class="switch_option capsule_btn p-0" v-show="isEdit">
                                        <h5 class="fs-14">Same as Billing Address</h5>
                                        <input type="checkbox" id="is-same-address" v-model="form.is_address_same" @change="handleSameAsBilling()" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                </h3>
                                <div class="setting_wpr col-2">
                                    <div class="form_grp w-100">
                                        <div class="group_item">
                                            <label class="input_label">
                                                <span>Street</span>
                                            </label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_address }">
                                                <Field v-model="form.shipping_address" name="shipping_address" :disabled="form.is_address_same">
                                                    <textarea cols="30" rows="10" v-model="form.shipping_address" placeholder="ex: 123 Anywhere Street." :disabled="form.is_address_same"></textarea>
                                                </Field>
                                            </div>
                                            <h4 v-else>{{ form.shipping_address ? form.shipping_address : '-' }}</h4>
                                            <ErrorMessage name="shipping_address" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Apartment</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_apt }">
                                                <Field type="text" name="shipping_apt" v-model="form.shipping_apt" placeholder="ex: Skyline" :disabled="form.is_address_same" />
                                            </div>
                                            <h4 v-else>{{ form.shipping_apt ? form.shipping_apt : '-' }}</h4>
                                            <ErrorMessage name="shipping_apt" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">City</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_city }">
                                                <Field type="text" name="shipping_city" v-model="form.shipping_city" placeholder="ex: San Francisco" :disabled="form.is_address_same" />
                                            </div>
                                            <h4 v-else>{{ form.shipping_city ? form.shipping_city : '-' }}</h4>
                                            <ErrorMessage name="shipping_city" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">State/Province</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_region }">
                                                <Field type="text" name="shipping_region" v-model="form.shipping_region" placeholder="ex: California" :disabled="form.is_address_same" />
                                            </div>
                                            <h4 v-else>{{ form.shipping_region ? form.shipping_region : '-' }}</h4>
                                            <ErrorMessage name="shipping_region" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Postal Code</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_postal_code }">
                                                <Field type="number" name="shipping_postal_code" v-model="form.shipping_postal_code" placeholder="ex: 94116" :disabled="form.is_address_same" />
                                            </div>
                                            <h4 v-else>{{ form.shipping_postal_code ? form.shipping_postal_code : '-' }}</h4>
                                            <ErrorMessage name="shipping_postal_code" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Country</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_country }">
                                                <Field name="shipping_country" v-model="form.shipping_country" :class="{ 'has-error': errors.shipping_country }">
                                                    <multiselect
                                                    v-model="form.shipping_country"
                                                    :options="countries"
                                                    value-prop="code"
                                                    label="country"
                                                    :searchable="true"
                                                    placeholder="Select country"
                                                    autocomplete="nofill"
                                                    :disabled="form.is_address_same ? true: false"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <h4 v-else>{{ form.shipping_country ? getCountryName(form.shipping_country) : '-' }}</h4>
                                            <ErrorMessage name="shipping_country" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="action_wpr" v-if="isEdit">
                <button type="button" class="btn cancel_btn" @click="handleCancelEdit()">Cancel</button>
                <button v-if="companyUserCan('update', 'checkouts')" :disabled="profileLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="profileLoader"></i> {{ profileLoader ? 'Saving' : 'Save' }}</button>
            </div>
        </Form>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))

    export default {
        name: 'Client General Tab',

        data () {
            return {
                isEdit: false,
                form:{
                    profile_pic: '',
                    first_name: '',
                    last_name: '',
                    occupation: '',
                    email: '',
                    phone: '',
                    billing_address: '',
                    billing_apt: '',
                    billing_city: '',
                    billing_region: '',
                    billing_postal_code: '',
                    billing_country: '',
                    is_address_same: 0,
                    shipping_address: '',
                    shipping_apt: '',
                    shipping_city: '',
                    shipping_region: '',
                    shipping_postal_code: '',
                    shipping_country: '',
                },
                isMounted: false,
                profileLink: process.env.VUE_APP_STRIPE_CUSTOMER_PROFILE_LINK,
            }
        },

        props: {
            selectedClient: {
                type: Object,
                default: () => {},
            },
        },

        emits: ['update:modelValue'],

        components:{
            ImageUpload,
            Form,
            Field,
            ErrorMessage,
        },

        watch: {
            form: {
                handler (form) {
                    const vm = this;

                    if (form.is_address_same) {
                        vm.handleSameAsBilling() ;
                    }
                },
                deep: true,
            }
        },

        computed: {
            ...mapState({
                countries: state => state.commonModule.countries,
                profileLoader: state => state.checkoutModule.updateClientProfileLoader,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN'
            }),
        },

        mounted () {
            const vm     = this;

            if (vm.countries.length == 0) {
                vm.getCountries();
            }

            vm.resetForm();
            vm.isMounted = true;
        },

        methods:{
            ...mapActions({
                getCountries: 'commonModule/getCountries',
                updateClientProfile: 'checkoutModule/updateClientProfile',
            }),

            editProfile () {
                const vm = this;

                vm.isEdit = true;
            },

            resetForm () {
                const vm = this;

                vm.form = JSON.parse(JSON.stringify(vm.selectedClient));
            },

            getCountryName (countryCode) {
                let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

                return regionNames.of(countryCode);
            },

            handleSameAsBilling() {
                const vm = this;

                if (vm.form.is_address_same) {
                    vm.form.shipping_country     = vm.form.billing_country;
                    vm.form.shipping_region      = vm.form.billing_region;
                    vm.form.shipping_city        = vm.form.billing_city;
                    vm.form.shipping_address     = vm.form.billing_address;
                    vm.form.shipping_apt         = vm.form.billing_apt;
                    vm.form.shipping_postal_code = vm.form.billing_postal_code;
                }
            },

            handleCancelEdit () {
                const vm = this;

                vm.form   = JSON.parse(JSON.stringify(vm.selectedClient));
                vm.isEdit = false;
            },

            handleSubmit () {
                const vm = this;

                vm.updateClientProfile(vm.form).then((result) => {
                    if (result) {
                        vm.isEdit = false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .side_info{
        flex: 0 0 340px;
        padding: 30px 40px;
        background: #fff;
        border-left: 1px solid #e9e9e9;
        overflow-y: auto;
    }
    .side_info::-webkit-scrollbar{
        width: 4px;
        background: transparent;
    }
    .side_info::-webkit-scrollbar-thumb{
        background: #bdbdbd;
        border-radius: 2px;
    }
    .side_info .ltv_card{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px;
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 20px;
    }
    .side_info .ltv_card .icon{
        width: 30px;
    }
    .side_info .ltv_card .sub_heading {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
    }
    .side_info .ltv_card .amt {
        font-size: 25px;
        line-height: 30px;
        font-weight: 300;
        color: #121525;
    }
    .side_info .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
    .side_info .tags li h4 {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        background: #2f7eed;
        color: #fff;
        margin: 0;
        border-radius: 3px;
        padding: 5px 12px;
    }
    .side_info .analytic_list{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 1px 0;
    }
    /* .side_info .analytic_list li{
        width: calc(50% - 5px);
    } */
    .side_info .analytic{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 12px;
        padding: 15px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.04);
    }
    .side_info .analytic .icon{
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f5f5;
        color: #ababab;
        font-size: 13px;
    }
    .side_info .analytic h5{
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .side_info .analytic h5 span{
        font-size: 20px;
        line-height: 25px;
        color: #121525;
        display: block;
        padding-top: 10px;
    }
    .profile_wpr{
        display: flex;
        gap: 50px;
    }
    .profile_left {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: sticky;
        top: 35px;
        align-self: flex-start;
    }
    .profile_left .user_img {
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;
        margin-bottom: 20px;
    }
    .profile_left .user_img img{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
        border: 1px solid #eee;
    }
    .profile_left a{
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        text-decoration: none;
        color: #2f7eed;
        display: block;
        border: 1px solid #7cb1fa;
        padding: 5px 10px;
        border-radius: 14px;
    }
    :deep(.tab_content.button-uploader) {
        width: 25px;
        height: 25px;
        font-size: 11px;
        text-align: center;
        line-height: 22px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #c4c4c4;
        color: #121525;
        position: absolute;
        right: 10px;
        bottom: 0;
        cursor: pointer;
    }
    .profile_right{
        flex: 0 1 800px;
    }
    .col-2, .col-3{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }
    .col-2 .form_grp{
        flex: 0 0 50%;
        margin: 0;
    }
    .col-3 .form_grp{
        flex: 0 0 33.333%;
        margin: 0;
    }
    .form_grp h4 {
        font-size: 15px;
        line-height: 22px;
        color: #5a5a5a;
        font-weight: 300;
        margin-top: 12px;
        display: flex;
    }
    .form_grp h4 .btn{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        background: #2f7eed;
        color: #fff;
        cursor: pointer;
        margin-left: 10px;
    }
    .edit_btn {
        font-size: 10px;
        line-height: 12px;
        background: #2f7eed;
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
        padding: 8px 20px;
        border-radius: 20px;
        cursor: pointer;
        font-family: 'Inter', sans-serif;
        margin: 0 0 15px;
    }

    .form_grp input.no_spin::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .form_grp input[type=number].no_spin {
        -moz-appearance: textfield;
    }
</style>
